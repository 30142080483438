(<template>
  <div :class="{'profile__whole-page-cont--business-view': !canEdit,
                'profile__whole-page-cont--opened-direct': !showWithDirectMobile,
                'profile__transparent-avatar-name': userIsInterpreter}"
       class="profile"
       itemscope
       itemtype="http://schema.org/Person">
    <div ref="pageTitle"
         class="profile__page-title">
      <router-link v-if="isMyProfile"
                   :to="editProfileLink"
                   class="sk-link profile__page-title-link">{{ $gettext('Edit profile') }}</router-link>
    </div>
    <section v-show="showWithDirectMobile"
             ref="avatarSection"
             class="profile__shadowed-section profile__shadowed-section--fixed-pos"
             :class="{'profile__shadowed-section--transparent': showTransparentCard}">
      <div v-if="isMyProfile && $route.name !== 'ServerProfile'"
           class="profile__edit-mobile-link-wrapper">
        <router-link :to="editProfileLink"
                     class="sk-link edit-pencil-btn" />
      </div>
      <avatar-name :params="avatarNameParams"
                   class="profile__name-avatar-section" />
    </section>
    <section v-if="showProfileData"
             class="profile__main-content-section">
      <profile-data class="profile__main-content-section--left"
                    :components-list="leftSectionComponents" />
      <profile-data class="profile__main-content-section--right"
                    :components-list="rightSectionComponents" />
    </section>
  </div>
</template>)

<script>
  import {mapGetters} from 'vuex';
  import AvatarName from '@/components/profile_components/show/AvatarName';
  import ProfilePrototype from '@/pages/shared_pages/profile/sh_ProfilePrototype';
  import ProfileData from '@/components/profile_components/ProfileData';

  export default {
    asyncData({store, route}) {
      let promisesArr = [];
      const id = route.params.id;
      const page = +route.query.page || 1;
      const items = +route.query.items || 5;
      const storeData = store.state.EditProfileStore.profileData;
      const storeUid = storeData.person
        ? storeData.person.uid
        : storeData.uid || '';
      const currentUserId = store.getters['UserInfoStore/userUid'];
      const userIsInterpreter = store.getters['UserInfoStore/userIsInterpreter'];
      const isOtherUserProfile = currentUserId !== id;
      const isNotPublicProfile = route.name !== 'PublicProfile';
      const hasTranslationOrIsDemanderViewing = userIsInterpreter ? store.getters['UserInfoStore/userIsSeller'] : isOtherUserProfile;

      if (isNotPublicProfile) {
        if (!storeData || storeUid != route.params.id) {
          promisesArr = [...promisesArr, store.dispatch('EditProfileStore/getUserProfile', {id}).then((res) => {
            if (!userIsInterpreter && res?.translator && Object.keys(res.translator).length > 0) {
              promisesArr = [...promisesArr, store.dispatch('EditProfileStore/getTools', res.interpreter.person.uid)];
              promisesArr = [...promisesArr, store.dispatch('EditProfileStore/getExperiences', res.interpreter.person.uid)];
            }
          })];
        }
        if (isOtherUserProfile || userIsInterpreter) {
          promisesArr = [...promisesArr, store.dispatch('EditProfileStore/getInterpreterFeedback', {id, params: {page, items}})];
        }
      } else {
        const {int_country: country, slug} = route.params;
        if (!store.state.EditProfileStore.profileData) {
          promisesArr = [...promisesArr, store.dispatch('EditProfileStore/getUserProfile', {country, slug})];
        }
      }
      // ------- Seller(Translator) Profile Info Calls --------  //
      if (userIsInterpreter && hasTranslationOrIsDemanderViewing && isNotPublicProfile) {
        promisesArr = [...promisesArr, store.dispatch('EditProfileStore/getTools', id)];
        promisesArr = [...promisesArr, store.dispatch('EditProfileStore/getExperiences', id)];
      }

      return promisesArr ? Promise.all(promisesArr) : promisesArr;
    },
    components: {
      'avatar-name': AvatarName,
      'profile-data': ProfileData
    },
    extends: ProfilePrototype,
    data() {
      return {
        canEdit: false,
        showPercentage: false,
        openedDirectMobile: false,
        directCalendarDate: (function makeStartDate(self) {
          const momentObj = self.$moment();
          const day = String(momentObj.date()).length > 1 ? momentObj.date() : `0${momentObj.date()}`;
          const month = String(momentObj.month() + 1).length > 1 ? +momentObj.month() + 1 : `0${momentObj.month() + 1}`;
          return `${momentObj.year()}-${month}-${day}`;
        }(this))
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userIsInterpreter', 'userIsNotInterpreter', 'userIsManager']),
      leftSectionComponents() {
        return [
          {name: 'about-info',
           condition: !!this.about,
           params: {aboutText: this.about, canEdit: this.canEdit}},
          {name: 'tools',
           condition: !!this.tools.length && this.isTranslator,
           params: {tools: this.tools, canEdit: this.canEdit}},
          {name: 'schools',
           condition: !!this.isAnySchools,
           params: {schools: this.education, canEdit: this.canEdit}},
          {name: 'courses',
           condition: !!this.isAnyCourses,
           params: {courses: this.courses, canEdit: this.canEdit}},
          {name: 'methods',
           condition: !!this.isAnyMethods(),
           params: {methods: this.interpretationMethods, canEdit: this.canEdit}},
          {name: 'int-types',
           condition: !!this.isAnyTypes,
           params: {interpretationTypes: this.intTypes, canEdit: this.canEdit}},
          {name: 'methods',
           condition: !!this.isAnyMethods(true),
           params: {methods: this.translationMethods, canEdit: this.canEdit, isTranslation: true}},
          {name: 'experiences',
           condition: !!this.experiences.length && this.isTranslator,
           params: {experiences: this.experiences, canEdit: this.canEdit}},
          // TODO: Refactor and remove not used anymore
          // {name: 'int-hours',
          //  condition: !!this.showIntHours,
          //  params: {intHours: this.intHours, canEdit: this.canEdit}}
        ];
        // {name: 'referral', condition: this.referalEnabled && this.canEdit, params: {referral: this.referralInfo, canEdit: this.canEdit}}
      },
      rightSectionComponents() {
        return [{
                  name: 'skills',
                  condition: this.isAnySkills,
                  params: {allSkills: this.skills, canEdit: this.canEdit}
                },
                {
                  name: 'badges',
                  condition: this.showBadges,
                  params: {badges: this.badges}
                },
                {
                  name: 'approved-interpreting-skills',
                  condition: this.isAnyInterpretationSkills,
                  params: {badges: this.badges}
                }, {
                  name: 'reviews',
                  condition: true,
                  params: {
                    publicFeedbacks: this.feedbacks,
                    stats: this.stats,
                    canEdit: this.canEdit,
                    totalPages: this.totalFeedbacks
                  }
                }].filter((component) => component.condition === true);
        // {name: 'referral', condition: this.referalEnabled && this.canEdit, params: {referral: this.referralInfo, canEdit: this.canEdit}}
      },
      avatarNameParams() {
        return {
          avatar: this.avatar,
          firstName: this.firstName,
          lastName: this.lastName,
          canEdit: this.canEdit,
          uid: this.uid,
          id: this.userId,
          tolkId: this.tolkId,
          gender: this.gender,
          stats: this.stats,
          city: this.city,
          country: this.country,
          bookable: this.isBookableUser,
          jobEntity: this.jobEntity,
          blockedByStatus: this.isBlockedByStatusInterpreter,
          profileData: {
            ...this.profileData || {},
            id: this.profilePerson.id || ''
          },
          showPercentage: this.showPercentage,
        };
      },
      editProfileLink() {
        if (this.userIsManager) return this.getEditProfileLink('ManagerUserProfileEdit');
        return this.userIsNotInterpreter ? this.getEditProfileLink('BuyerUserProfileEdit') : this.getEditProfileLink('ServerProfileEdit');
      },
      isPublicProfile() { return this.$route.name == 'PublicProfile'; },
      isMyProfile() { return this.$route.params.id == this.userUid; },
      globalProgress() { return this.$store.state.GlobalStore.mainProgressActive; },
      clientWidth() { return this.$store.state.GlobalStore.clientWidth; },
      showIntHours() { return this.isPublicProfile ? this.intHours : this.profileUserRole == 'server'; },
      showWithDirectMobile() {
        if (this.clientWidth > 767) {
          return true;
        } else {
          return !this.openedDirectMobile;
        }
      },
      showTransparentCard() {
        return (this.userIsNotInterpreter || this.userIsManager) && !this.isMyProfile;
      },
      isAnyAreas() {
        if (this.expertiseAreas && this.expertiseAreas.length) {
          for (const area of this.expertiseAreas) {
            if (area.checked) {
              return true;
            }
          }

          return false;
        }
      },
      isAnyTypes() {
        if (this.intTypes && this.intTypes.length) {
          for (const area of this.intTypes) {
            if (area.checked) {
              return true;
            }
          }

          return false;
        }
      },
      isAnySchools() {
        return this.education && this.education.length;
      },
      isAnyCourses() {
        return this.courses && this.courses.length;
      },
      showBadges() {
        return this.badges && this.badges.length > 0;
      },
      showProfileData() {
        return this.profileUserRole == 'server'
          || this.isAnySkills
          || this.about
          || this.showBadges
          || this.isAnyAreas
          || this.isAnyMethods()
          || this.isAnyTypes
          || this.isAnySchools
          || this.isAnyCourses
          || this.showIntHours;
      }
    },
    methods: {
      isAnyMethods(isTranslation = false) {
        const methodsList = isTranslation ? this.translationMethods : this.interpretationMethods;
        if (methodsList && methodsList.length) {
          for (const area of methodsList) {
            if (area.checked) {
              return true;
            }
          }

          return false;
        }
      },
    },
    beforeRouteLeave(to, from, next) {
      this.$store.commit('EditProfileStore/removeUserProfile');
      this.$store.commit('EditProfileStore/removeTools');
      this.$store.commit('EditProfileStore/removeExperiences');
      next();
    }
  };
</script>

<style src="@assets/css/profile_pages.css"></style>

<style scoped>
  .profile__page-title {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 40px;
    font-size: 18px;
    text-align: center;
  }

  .profile__shadowed-section {
    display: flex;
    flex-wrap: wrap;
    padding: 0 30px;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  }

  .profile__shadowed-section--fixed-pos {
    width: 100%;
    max-width: 1050px;
    margin: 0 auto;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 9px 0 rgb(0 0 0 / 10%);
  }

  .profile__shadowed-section--transparent {
    background-color: transparent;
    box-shadow: none;
  }

  .profile__public-profile-main-cont .profile__shadowed-section {
    justify-content: center;
  }

  .profile__name-avatar-section {
    position: relative;
    flex-grow: 1;
    flex-shrink: 0;
    width: 33.333333%;
    min-width: 400px;
    margin: 30px 0;
  }

  .profile__public-profile-main-cont .profile__name-avatar-section {
    max-width: 750px;
  }

  @media (max-width: 767px) {
    .profile__page-title {
      display: none;
    }

    .profile__edit-mobile-link-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
    }

    .profile__shadowed-section--fixed-pos {
      top: 45px;
    }

    .logged-in-root--is-godmode .profile__shadowed-section--fixed-pos {
      top: 135px;
    }

    .profile__name-avatar-section {
      width: 100%;
      min-width: auto;
      margin: 15px 0;
    }

    .profile__whole-page-cont--opened-direct .profile__main-content-section {
      margin-top: 0;
      padding: 0 10px;
      background-color: transparent;
    }

    .profile__main-content-section {
      margin: 15px auto 0;
      box-shadow: none;
    }

    .profile__whole-page-cont--business-view .profile__main-content-section {
      margin-top: 20px;
    }

    .logged-in-root--is-godmode .profile__main-content-section {
      margin-top: 135px;
    }

    .profile__whole-page-cont--opened-direct .profile__additional-info-wrapper {
      display: none;
    }

    .profile__shadowed-section {
      padding: 0 15px;
    }
  }
</style>
